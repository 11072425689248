var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    staticClass: "analyzers",
    attrs: {
      "width": "100%",
      "elevation": "5"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.value.type) + " ")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.value.name,
      "label": "Name",
      "disabled": _vm.disabled,
      "rules": [_vm.formRules.required]
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('name', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Analyzer type",
      "items": _vm.analyzerSchemas.map(function (analyzer) {
        return analyzer.type;
      }),
      "value": _vm.value.type,
      "disabled": _vm.disabled,
      "rules": [_vm.formRules.required]
    },
    on: {
      "input": function input($event) {
        return _vm.updateType($event);
      }
    }
  })], 1), _c('v-btn', {
    staticClass: "align-self-center",
    attrs: {
      "disabled": _vm.disabled,
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('delete');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1), _c('v-row', {
    staticClass: "d-flex flex-column mb-3"
  }, [_c('v-col', [_c('elevation-mapping', {
    attrs: {
      "value": _vm.value.gov_srf_map,
      "name": 'Governing SRF Map',
      "disabled": _vm.disabled,
      "factor": 'srf'
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('gov_srf_map', $event, _vm.value);
      }
    }
  })], 1)], 1), _vm.value.type === 'EC3BucklingAnalyzer' ? _c('ec3-buckling-analyzer', {
    attrs: {
      "value": _vm.value,
      "is-global": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  }) : _vm._e(), _vm.value.type === 'IECFlangeAnalyzer' ? _c('iec-flange-analyzer', {
    attrs: {
      "value": _vm.value,
      "is-global": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  }) : _vm._e(), _vm.value.type === 'EC3DamageAnalyzer' ? _c('ec3-damage-analyzer', {
    attrs: {
      "value": _vm.value,
      "is-global": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  }) : _vm._e(), _vm.value.type === 'DamageEquivalentAnalyzer' ? _c('damage-equivalent-analyzer', {
    attrs: {
      "value": _vm.value,
      "is-global": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  }) : _vm._e(), _vm.value.type === 'MarkovEquivalentAnalyzer' ? _c('markov-equivalent-analyzer', {
    attrs: {
      "value": _vm.value,
      "is-global": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  }) : _vm._e(), _vm.value.type === 'MarkovAnalyzer' ? _c('markov-analyzer', {
    attrs: {
      "value": _vm.value,
      "is-global": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }