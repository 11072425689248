var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "tile": ""
    }
  }, [_c('v-card-title', [_c('span', [_c('bordered-text-span', {
    attrs: {
      "text": String(_vm.numRefs),
      "padding": "3px"
    }
  }), _vm._v(" " + _vm._s(_vm.numRefs > 1 ? 'Towers' : 'Tower') + " Using " + _vm._s(_vm.objectName) + " ")], 1)]), _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "color": "primary"
    }
  }, _vm._l(_vm.references, function (reference) {
    return _c('v-list-item', {
      key: reference[1],
      on: {
        "click": function click($event) {
          return _vm.routeToTower(reference[1]);
        }
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "size": "30",
        "color": "primary"
      }
    }, [_vm._v(" mdi-wind-turbine ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "text-wrap pb-1"
    }, [[_c('span', {
      staticClass: "text-subtitle-2 mr-2"
    }, [_vm._v(" " + _vm._s(reference[0]) + " ")]), _c('v-btn', {
      attrs: {
        "x-small": "",
        "rounded": "",
        "outlined": "",
        "color": _vm.statusColor(reference[2])
      }
    }, [_vm._v(" " + _vm._s(reference[2]) + " ")])]], 2)], 1)], 1);
  }), 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.show = false;
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }