var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Misalignment",
      "value": _vm.value.misalignment,
      "units": "m/m",
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('misalignment', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Safety factor",
      "value": _vm.value.safety_factor,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('safety_factor', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Bolt safety factor",
      "value": _vm.value.safety_factor_bolt,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('safety_factor_bolt', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Tower partial load factor favorable",
      "value": _vm.value.tower_partial_load_factor_favorable,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('tower_partial_load_factor_favorable', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }