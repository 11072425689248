var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$store.state.error ? _c('v-alert', {
    attrs: {
      "type": "error",
      "dismissible": ""
    },
    model: {
      value: _vm.$store.state.showError,
      callback: function callback($$v) {
        _vm.$set(_vm.$store.state, "showError", $$v);
      },
      expression: "$store.state.showError"
    }
  }, [_vm.$store.state.error.detail ? _c('p', [_vm._v(_vm._s(_vm.$store.state.error.detail))]) : _c('p', {
    staticClass: "ma-0"
  }, [_vm._v(_vm._s(_vm.$store.state.error))]), _vm.$store.state.error.errors ? _c('p', [_vm._v(" " + _vm._s(JSON.stringify(_vm.$store.state.error.errors)) + " ")]) : _vm._e(), _vm.$store.state.error.references ? _c('div', [_c('p', [_vm._v("This resource is being referenced in the following places.")]), _c('ul', _vm._l(_vm.$store.state.error.references, function (ref, index) {
    return _c('li', {
      key: index
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: _vm.resourceToRoute(ref[0]),
          params: {
            uuid: ref[1]
          }
        }
      }
    }, [_vm._v(" " + _vm._s(ref[1]) + " ")])], 1);
  }), 0)]) : _vm._e()]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }