var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-toolbar', {
    attrs: {
      "flat": "",
      "short": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Section " + _vm._s(_vm.sectionIndex + 1))]), _c('v-btn', {
    attrs: {
      "small": "",
      "icon": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('duplicate');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-file-multiple")])], 1), _c('v-btn', {
    attrs: {
      "small": "",
      "icon": "",
      "disabled": _vm.preventDelete || _vm.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('delete');
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-delete ")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('section-features', {
    attrs: {
      "value": _vm.value.features,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('features', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('unit-results', {
    attrs: {
      "results": _vm.sectionResults
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "reverseorder"
  }, _vm._l(_vm.value.regions, function (region, regionIndex) {
    var _vm$model;
    return _c('region-intent', {
      key: regionIndex,
      attrs: {
        "value": region,
        "section-index": _vm.sectionIndex,
        "region-index": regionIndex,
        "prevent-delete": _vm.value.regions.length == 1,
        "shells": (_vm$model = _vm.model) === null || _vm$model === void 0 ? void 0 : _vm$model.shells,
        "disabled": _vm.disabled
      },
      on: {
        "input": function input($event) {
          return _vm.updateValue(['regions', regionIndex], $event, _vm.value);
        },
        "duplicate": function duplicate($event) {
          return _vm.duplicateRegion(regionIndex);
        },
        "delete": function _delete($event) {
          return _vm.deleteRegion(regionIndex);
        }
      }
    });
  }), 1)], 1), _c('v-card', [_c('v-card-title', [_vm._v("Lower Flange")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('flange-spec', {
    attrs: {
      "value": _vm.value.lower_flange,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('lower_flange', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('unit-results', {
    attrs: {
      "results": _vm.flangeResults
    }
  })], 1)], 1)], 1)], 1), _c('v-card', [_c('v-card-title', [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "nudge-right": "150"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-icon', _vm._g({
          attrs: {
            "color": "primary"
          }
        }, on), [_vm._v(" mdi-information-outline ")])];
      }
    }])
  }, [_vm._v(" Notes added to each section on blueprint report ")]), _c('span', {
    staticClass: "mx-2"
  }, [_vm._v("Section " + _vm._s(_vm.sectionIndex + 1) + " Notes")]), _c('v-menu', {
    attrs: {
      "open-on-hover": "",
      "open-delay": "200",
      "offset-x": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-eye ")])];
      }
    }])
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Preview of Final Section " + _vm._s(_vm.sectionIndex + 1) + " Note")]), _c('v-card-text', [_c('h3', [_vm._v("Notes")]), _vm._l(_vm.previewSecNote.split('\n'), function (note, index) {
    return _c('span', {
      key: index,
      staticClass: "body-2"
    }, [_vm._v(" " + _vm._s(note) + " "), _c('br')]);
  })], 2)], 1)], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Flange Material",
      "value": _vm.value.flange_material,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('flange_material', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Shell Material",
      "value": _vm.value.shell_material,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('shell_material', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-textarea', {
    attrs: {
      "value": _vm.value.extra_blueprint_notes,
      "auto-grow": "",
      "no-resize": "",
      "label": "Extra Section Notes for Blueprint"
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('extra_blueprint_notes', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }