var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm.showWarning && _vm.$route.name === 'edit-load-set' ? _c('v-alert', {
    staticClass: "mb-2",
    attrs: {
      "type": "warning",
      "dismissible": ""
    }
  }, [_c('ul', _vm._l(_vm.warnings, function (warning, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" " + _vm._s(warning) + " ")]);
  }), 0)]) : _vm._e(), _vm.$route.name === 'create-load-set' ? _c('div', [_c('load-set', {
    ref: "loadSetRef",
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      },
      "validityChange": function validityChange($event) {
        return _vm.$emit('validityChange', $event);
      }
    }
  })], 1) : _c('div', [_vm.value ? _c('load-set', {
    ref: "loadSetRef",
    attrs: {
      "editLoadset": true,
      "value": _vm.value,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      },
      "validityChange": function validityChange($event) {
        return _vm.$emit('validityChange', $event);
      }
    }
  }) : _c('v-progress-circular', {
    staticClass: "d-flex justify-center my-2",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "indeterminate": "",
      "width": "3",
      "size": "30",
      "color": "primary"
    }
  }, [_vm._v(" Loading LoadSet... ")]), _vm.moreThanOneLoadCase ? _c('div', {
    staticClass: "mt-2",
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" Cannot display S3 file - More than one load type found"), _c('br'), _c('strong', [_vm._v("One load type per file is required for standardized input")])]) : _vm.loadSetS3 === null ? _c('div', {
    staticClass: "mt-2",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "large": ""
    },
    on: {
      "click": _vm.handleDownloadCSV
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-download")])], 1), _c('br'), _c('strong', [_vm._v("Error Loading S3 File - Invalid Format")]), _c('br'), _vm._v(" Unexpected column or expected column not found."), _c('br')], 1) : _vm.loadSetS3 || _vm.loadTypeIsMarkov ? _c('v-card', {
    staticClass: "mt-4",
    staticStyle: {
      "margin": "0 auto"
    },
    attrs: {
      "elevation": "3",
      "max-width": "1000px"
    }
  }, [_c('v-card-title', {
    staticClass: "pb-2"
  }, [_c('div', {
    staticClass: "mr-4"
  }, [_vm._v(_vm._s(_vm.value.load_set_type) + " LoadSet Data")]), _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": "primary",
            "loading": _vm.downloading
          },
          on: {
            "click": _vm.handleDownloadCSV
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-download")])], 1)];
      }
    }])
  }, [_vm._v(" Download CSV ")]), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _vm.loadTypeIsMarkov ? _c('v-card-subtitle', [_vm._v(" Note: Downloading Markov data may take a few minutes ")]) : _vm._e(), _vm.loadTypeIsMarkov ? _c('v-data-table', {
    attrs: {
      "headers": [],
      "items": [],
      "hide-default-footer": "",
      "no-data-text": "No preview available - Markov data exceeds browser capacity"
    }
  }) : _c('v-data-table', {
    attrs: {
      "headers": _vm.loadSetHeaders,
      "items": _vm.loadSetS3,
      "loading-text": "Loading LoadSet...",
      "caption": !_vm.loadSetType && !_vm.headersExist ? 'Load Set Type needs to be selected & saved for data preview' : '',
      "search": _vm.search
    }
  })], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }