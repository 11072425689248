var render = function render(){
  var _vm$value, _vm$value2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.value.name && _vm.$route.name !== 'create-design-basis' ? _c('v-progress-circular', {
    staticClass: "d-flex justify-center my-2",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "indeterminate": "",
      "width": "3",
      "size": "30",
      "color": "primary"
    }
  }, [_vm._v(" Loading General Properties... ")]) : _c('v-card', {
    staticClass: "ma-2"
  }, [_vm.isEmptyAnalyzers ? _c('v-alert', {
    attrs: {
      "text": "",
      "type": "info",
      "color": "info"
    }
  }, [_vm._v(" You must have at least one analyzer ")]) : _vm._e(), _c('v-card', [_c('v-card-title', {
    staticClass: "headline v-subheader justify-center"
  }, [_vm._v(" General Properties ")]), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Name",
      "value": _vm.value.name,
      "disabled": _vm.disabled,
      "rules": [_vm.formRules.required]
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('name', $event, _vm.value);
      }
    }
  }), _c('notes', {
    attrs: {
      "value": _vm.handleInternalNotes((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.notes, (_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.internal_notes),
      "label": "Internal Notes",
      "notes-info": "Internal Notes are never added to reports",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('internal_notes', $event, _vm.value);
      }
    }
  }), _c('notes', {
    attrs: {
      "value": _vm.value.blueprint_notes,
      "label": "Blueprint Report Notes",
      "notes-info": "Default Blueprint Design Basis Notes for pg. 1 'Tower Design Basis'",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('blueprint_notes', $event, _vm.value);
      }
    }
  }), _c('notes', {
    attrs: {
      "value": _vm.value.certification_notes,
      "label": "Certification Report Notes",
      "notes-info": "Default Certification Report Notes for pg. 3 'Design Notes'",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('certification_notes', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-card', [_c('v-card-title', {
    staticClass: "headline v-subheader justify-center"
  }, [_vm._v(" Analyzers ")]), _c('v-card-actions', {
    staticClass: "pt-0"
  }, [_c('v-btn', {
    attrs: {
      "rounded": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.addAnalyzer();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" Add Analyzer ")], 1)], 1), _c('v-card-text', [_c('v-row', _vm._l(_vm.value.analyzers, function (analyzer, idx) {
    return _c('analyzer', {
      key: idx,
      attrs: {
        "value": analyzer,
        "disabled": _vm.disabled
      },
      on: {
        "input": function input($event) {
          return _vm.updateValue(['analyzers', idx], $event, _vm.value);
        },
        "delete": function _delete($event) {
          return _vm.deleteAnalyzer(idx);
        }
      }
    });
  }), 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }