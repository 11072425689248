var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "clipped": "",
      "permanent": ""
    }
  }, [_c('new-tower-dialog'), _c('nav-list', {
    attrs: {
      "objects": _vm.$store.state.turbines,
      "sublist": _vm.$store.state.modelNumbers,
      "group-by": "data.oem"
    }
  })], 1), _vm.towerListRoute ? [_c('h2', {
    staticClass: "mb-2"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.turbineName))]), !_vm.isOnlyTurbineRoute ? _c('bordered-text-span', {
    attrs: {
      "text": String(_vm.modelName),
      "padding": "3px"
    }
  }) : _c('bordered-text-span', {
    attrs: {
      "text": "Other",
      "padding": "3px"
    }
  })], 1), !_vm.isOnlyTurbineRoute ? [_c('tower-list-data-card', {
    attrs: {
      "title": "Tower Releases",
      "headers": _vm.towerReleaseHeaders,
      "expandedHeaders": _vm.towerListHeaders,
      "items": _vm.towerReleaseItems,
      "fetching": _vm.fetching,
      "optional-props": {
        'show-expand': true
      }
    }
  }), _c('tower-list-data-card', {
    attrs: {
      "title": "Tower Drafts",
      "headers": _vm.towerListHeaders,
      "items": _vm.towerDrafts,
      "showRevisionSubtitle": true,
      "removeTableRevision": true,
      "variableStatus": true,
      "fetching": _vm.fetching,
      "optional-props": {
        'hide-default-header': false
      }
    }
  })] : [_c('tower-list-data-card', {
    attrs: {
      "title": "All Towers with ".concat(_vm.turbineName),
      "headers": _vm.towerListHeaders,
      "items": _vm.$store.state.towers,
      "fetching": _vm.fetching
    }
  })]] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }