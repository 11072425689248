var render = function render(){
  var _vm$value, _vm$value2, _vm$value$flange, _vm$value$flange$prof, _vm$value$flange2, _vm$value$flange3, _vm$value$flange4, _vm$value$flange5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [!_vm.value.name && _vm.$route.name !== 'create-turbine' ? _c('v-progress-circular', {
    staticClass: "d-flex justify-center my-2",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "indeterminate": "",
      "width": "3",
      "size": "30",
      "color": "primary"
    }
  }, [_vm._v(" Loading General Properties... ")]) : _c('v-card', {
    staticClass: "my-1",
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-title', {
    staticClass: "headline v-subheader justify-center"
  }, [_vm._v(" General Properties ")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-row', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-select', {
    staticClass: "mb-1",
    attrs: {
      "label": "OEM",
      "disabled": _vm.disabled,
      "items": _vm.oems,
      "item-text": "text",
      "item-value": "value",
      "value": _vm.value.oem,
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.updateValue('oem', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Name",
      "value": _vm.value.name,
      "disabled": _vm.disabled,
      "rules": [_vm.formRules.required]
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('name', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('notes', {
    attrs: {
      "value": _vm.handleInternalNotes((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.notes, (_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.internal_notes),
      "label": "Internal Notes",
      "notes-info": "Internal Notes are never added to reports",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('internal_notes', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Power Rating",
      "units": "kW",
      "value": _vm.value.power_rating,
      "disabled": _vm.disabled,
      "dense": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('power_rating', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Rotor Diameter",
      "units": "m",
      "value": _vm.value.rotor_diameter,
      "disabled": _vm.disabled,
      "dense": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('rotor_diameter', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Flange top to HH",
      "units": "m",
      "value": _vm.value.flange_to_hub_height,
      "disabled": _vm.disabled,
      "dense": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('flange_to_hub_height', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Max Diameter at Blade Tip",
      "units": "m",
      "value": _vm.value.blade_tip_diameter,
      "disabled": _vm.disabled,
      "placeholder": "4",
      "dense": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('blade_tip_diameter', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Blade Camber Adjust",
      "units": "mm",
      "value": _vm.value.blade_camber_adjust,
      "disabled": _vm.disabled,
      "placeholder": "0",
      "dense": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('blade_camber_adjust', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('center-of-gravity', {
    attrs: {
      "value": _vm.value.center_of_gravity,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('center_of_gravity', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Nominal Head Mass",
      "units": "kg",
      "value": _vm.value.head_mass,
      "disabled": _vm.disabled,
      "dense": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('head_mass', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Min Head Mass",
      "units": "kg",
      "value": _vm.value.min_head_mass,
      "disabled": _vm.disabled,
      "clearable": "",
      "placeholder": '-5% = ' + (_vm.value.head_mass * 0.95).toFixed(2).toString(),
      "dense": "",
      "rules": _vm.lessThan(_vm.value.head_mass)
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('min_head_mass', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Max Head Mass",
      "units": "kg",
      "value": _vm.value.max_head_mass,
      "disabled": _vm.disabled,
      "clearable": "",
      "placeholder": '+5% = ' + (_vm.value.head_mass * 1.05).toFixed(2).toString(),
      "dense": "",
      "rules": _vm.greaterThan(_vm.value.head_mass)
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('max_head_mass', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "my-2",
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-title', {
    staticClass: "headline v-subheader justify-center"
  }, [_vm._v(" Excluded Frequencies ")]), _c('v-card-text', [_c('custom-data-table', {
    attrs: {
      "headers": _vm.excludedFrequencyHeaders,
      "items": _vm.computedFrequencyRanges,
      "value-units": _vm.excludedFrequencyValueUnits,
      "data-empty-row": _vm.excludedFrequencyEmptyRow,
      "optional-props": {
        'disable-pagination': true,
        'hide-default-footer': true,
        'dense': true
      },
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('excluded_frequency_ranges', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "my-2",
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-title', {
    staticClass: "v-subheader headline justify-center"
  }, [_vm._v(" Tower Top Flange ")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "md": "3"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "disabled": _vm.disabled,
      "label": "Enable Min Thickness Constraint?"
    },
    on: {
      "change": function change($event) {
        !$event ? _vm.updateValue('min_thickness', null, _vm.value) : null;
      }
    },
    model: {
      value: _vm.enableMinThickness,
      callback: function callback($$v) {
        _vm.enableMinThickness = $$v;
      },
      expression: "enableMinThickness"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "md": "5",
      "lg": "3"
    }
  }, [_vm.enableMinThickness ? _c('numeric-text-field', {
    attrs: {
      "disabled": _vm.disabled,
      "label": "Min. Thickness",
      "units": "mm",
      "value": _vm.value.min_thickness,
      "placeholder": (_vm$value$flange = _vm.value.flange) === null || _vm$value$flange === void 0 ? void 0 : (_vm$value$flange$prof = _vm$value$flange.profile) === null || _vm$value$flange$prof === void 0 ? void 0 : _vm$value$flange$prof.neck_thickness.toString()
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('min_thickness', $event, _vm.value);
      }
    }
  }) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Anchor",
      "value": (_vm$value$flange2 = _vm.value.flange) === null || _vm$value$flange2 === void 0 ? void 0 : _vm$value$flange2.anchor,
      "items": [{
        text: 'Top',
        value: 'TOP'
      }, {
        text: 'Base',
        value: 'BASE'
      }],
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('flange.anchor', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Shell Diameter",
      "units": "m",
      "value": (_vm$value$flange3 = _vm.value.flange) === null || _vm$value$flange3 === void 0 ? void 0 : _vm$value$flange3.shell_diameter,
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('flange.shell_diameter', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('bolt-circle', {
    attrs: {
      "value": (_vm$value$flange4 = _vm.value.flange) === null || _vm$value$flange4 === void 0 ? void 0 : _vm$value$flange4.bolt_circle,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('flange.bolt_circle', $event, _vm.value);
      }
    }
  }), _c('flange-profile', {
    attrs: {
      "value": (_vm$value$flange5 = _vm.value.flange) === null || _vm$value$flange5 === void 0 ? void 0 : _vm$value$flange5.profile,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('flange.profile', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }