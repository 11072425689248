var render = function render(){
  var _vm$value, _vm$value2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticStyle: {
      "margin": "0 auto"
    },
    attrs: {
      "elevation": "4",
      "max-width": "1000px"
    }
  }, [_vm.markovZipWarning ? _c('v-alert', {
    attrs: {
      "type": "warning",
      "dense": ""
    }
  }, [_vm._v(" Can only parse Markov Zip files for 'GE' Turbines ")]) : _vm._e(), _vm.editLoadset ? _c('v-card-title', [_vm._v("Edit Load Set")]) : _c('v-card-title', [_vm._v(" New Load Set "), _c('span', [_c('v-menu', {
    attrs: {
      "open-on-hover": "",
      "transition": "slide-x-transition",
      "offset-x": "",
      "min-width": "auto",
      "nudge-top": "15"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-information")])], 1)];
      }
    }])
  }, [_c('v-card', {
    attrs: {
      "width": "600px",
      "height": "480px"
    }
  }, [_c('v-card-title', [_vm._v("Load Set Info")]), _c('v-card-subtitle', [_vm._v("Load Set Import Coordinate System")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-avatar', {
    attrs: {
      "rounded": "0",
      "size": "380"
    }
  }, [_c('v-img', {
    attrs: {
      "alt": "loadset_coordinate_system.png",
      "src": require("@/assets/loadset_coordinate_system.png"),
      "contain": ""
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-card-text', {
    staticClass: "text-wrap"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Note:")]), _vm._v(" Load Set imports (notably Buckling load sets) must comply with the axes in this diagram."), _c('br'), _c('br'), _vm._v(" Careful as not all manufacturers use the same coordinate system convention. Check with the OEM that produced your load set. ")])], 1)], 1)], 1)], 1)], 1)]), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-form', {
    model: {
      value: _vm.isValidForm,
      callback: function callback($$v) {
        _vm.isValidForm = $$v;
      },
      expression: "isValidForm"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.value.name,
      "dense": "",
      "label": "Name",
      "rules": [_vm.formRules.required],
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('name', $event, _vm.value);
      }
    }
  }), _c('v-select', {
    attrs: {
      "value": _vm.value.load_set_type,
      "dense": "",
      "label": "Load Set Type",
      "items": _vm.loadSetTypes,
      "rules": [_vm.formRules.required],
      "disabled": _vm.$route.name === 'edit-load-set' && !_vm.reuploadFile || _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('load_set_type', $event, _vm.value);
      }
    }
  }), _c('library-select', {
    attrs: {
      "value": _vm.value.turbine,
      "label": "Turbine",
      "items": _vm.$store.state.turbines,
      "item-text": "data.name",
      "item-value": "uuid",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('turbine', $event, _vm.value);
      }
    }
  }), _c('v-row', {
    staticClass: "mb-2"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "value": _vm.value.hub_height,
      "dense": "",
      "label": "Hub Height",
      "units": "m",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('hub_height', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "value": _vm.value.wind_class,
      "dense": "",
      "label": "Wind Class/Site",
      "items": _vm.windClasses,
      "item-text": "text",
      "item-value": "value",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('wind_class', $event, _vm.value);
      }
    }
  })], 1)], 1), _vm.editLoadset ? _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "label": "Re-upload a csv loadset file?"
    },
    model: {
      value: _vm.reuploadFile,
      callback: function callback($$v) {
        _vm.reuploadFile = $$v;
      },
      expression: "reuploadFile"
    }
  }) : _vm._e(), _c('v-row', [(_vm.createLoadSetRoute || _vm.reuploadFile) && _vm.value.load_set_type && _vm.value.load_set_type !== 'Markov ZIP' ? [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('h4', [_vm._v(" Example CSV for "), _c('span', [_vm._v(_vm._s(_vm.value.load_set_type))]), _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": "primary"
          },
          on: {
            "click": _vm.handleDownloadCSV
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-download")])], 1)];
      }
    }], null, false, 1597466667)
  }, [_vm._v(" Download Default CSV ")])], 1), _c('ul', {
    staticClass: "mb-2"
  }, [_c('li', [_vm._v("Note: Do not include units in column headers")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "mb-7 ml-4",
    attrs: {
      "headers": _vm.defaultCSVHeaders,
      "items": _vm.defaultCSVItems,
      "disable-pagination": "",
      "hide-default-footer": "",
      "dense": "",
      "disable-sort": "",
      "no-data-text": ""
    }
  })], 1)] : _vm._e()], 2), _vm.value.load_set_type === 'Damage Equivalent' && (_vm.reuploadFile || _vm.createLoadSetRoute) ? _c('v-row', {
    staticClass: "ma-0"
  }, [_c('span', {
    staticClass: "mb-4 font-weight-bold"
  }, [_vm._v("Advanced Settings (defaults are preset)")]), _c('v-col', {
    staticClass: "py-0 mb-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "value": _vm.value.parserVars.reference_cycles,
      "dense": "",
      "label": "Reference Cycles",
      "rules": [_vm.formRules.required],
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('parserVars.reference_cycles', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 mb-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "value": _vm.value.parserVars.slope,
      "dense": "",
      "label": "Slope",
      "rules": [_vm.formRules.required],
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('parserVars.slope', $event, _vm.value);
      }
    }
  })], 1)], 1) : _vm._e(), _vm.value.load_set_type === 'Buckling' && (_vm.reuploadFile || _vm.createLoadSetRoute) ? _c('v-row', {
    staticClass: "ma-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Advanced Settings (defaults are preset)")]), _c('v-col', {
    staticClass: "py-0 mb-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "input-value": _vm.value.parserVars.f_includes_tower_mass,
      "label": "f includes tower mass?",
      "disabled": _vm.disabled
    },
    on: {
      "change": function change($event) {
        return _vm.updateValue('parserVars.f_includes_tower_mass', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "input-value": _vm.value.parserVars.m_includes_tilt_moment,
      "label": "m includes tilt moment?",
      "disabled": _vm.disabled
    },
    on: {
      "change": function change($event) {
        return _vm.updateValue('parserVars.m_includes_tilt_moment', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "value": _vm.value.parserVars.unit_overrides.elevation,
      "dense": "",
      "label": "Elevation",
      "items": _vm.unit_override_items.elevation,
      "rules": [_vm.formRules.required],
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('parserVars.unit_overrides.elevation', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "value": _vm.value.parserVars.unit_overrides.f_x,
      "dense": "",
      "label": "f_x",
      "items": _vm.unit_override_items.force,
      "rules": [_vm.formRules.required],
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('parserVars.unit_overrides.f_x', $event, _vm.value);
      }
    }
  }), _c('v-select', {
    attrs: {
      "value": _vm.value.parserVars.unit_overrides.f_y,
      "dense": "",
      "label": "f_y",
      "items": _vm.unit_override_items.force,
      "rules": [_vm.formRules.required],
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('parserVars.unit_overrides.f_y', $event, _vm.value);
      }
    }
  }), _c('v-select', {
    attrs: {
      "value": _vm.value.parserVars.unit_overrides.f_z,
      "dense": "",
      "label": "f_z",
      "items": _vm.unit_override_items.force,
      "rules": [_vm.formRules.required],
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('parserVars.unit_overrides.f_z', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "value": _vm.value.parserVars.unit_overrides.m_x,
      "dense": "",
      "label": "m_x",
      "items": _vm.unit_override_items.moment,
      "rules": [_vm.formRules.required],
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('parserVars.unit_overrides.m_x', $event, _vm.value);
      }
    }
  }), _c('v-select', {
    attrs: {
      "value": _vm.value.parserVars.unit_overrides.m_y,
      "dense": "",
      "label": "m_y",
      "items": _vm.unit_override_items.moment,
      "rules": [_vm.formRules.required],
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('parserVars.unit_overrides.m_y', $event, _vm.value);
      }
    }
  }), _c('v-select', {
    attrs: {
      "value": _vm.value.parserVars.unit_overrides.m_z,
      "dense": "",
      "label": "m_z",
      "items": _vm.unit_override_items.moment,
      "rules": [_vm.formRules.required],
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('parserVars.unit_overrides.m_z', $event, _vm.value);
      }
    }
  })], 1)], 1) : _vm._e(), _vm.reuploadFile || !_vm.editLoadset ? _c('p', {
    staticClass: "mb-2 font-weight-bold"
  }, [_vm._v(" Add " + _vm._s(_vm.value.load_set_type !== 'Markov ZIP' ? 'csv' : 'zip') + " file: ")]) : _vm._e(), _vm.reuploadFile || !_vm.editLoadset ? _c('form', {
    ref: "fileField",
    attrs: {
      "enctype": "multipart/form-data"
    }
  }, [_c('input', {
    attrs: {
      "id": "file",
      "type": "file",
      "name": _vm.uploadFieldName,
      "accept": _vm.value.load_set_type !== 'Markov ZIP' ? '.csv' : '.zip',
      "disabled": _vm.disabled
    },
    on: {
      "change": function change($event) {
        return _vm.filesChange($event.target.files);
      }
    }
  })]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('notes', {
    attrs: {
      "value": _vm.handleInternalNotes((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.notes, (_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.internal_notes),
      "label": "Internal Notes",
      "notes-info": "Internal Notes are never added to reports",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('internal_notes', $event, _vm.value);
      }
    }
  }), _c('notes', {
    attrs: {
      "value": _vm.value.certification_notes,
      "label": "Certification Report Notes",
      "notes-info": "Certification Notes: Added to pg. 3 'Design Notes'",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('certification_notes', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "pt-0"
  }, [['Markov', 'Markov ZIP'].includes(_vm.value.load_set_type) ? _c('div', {
    staticClass: "grey--text text-subtitle-2 font-weight-normal mt-3 ml-2"
  }, [_vm._v(" Note: Markov file uploads may take a while. ")]) : _vm._e(), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }