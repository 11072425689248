var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', [_c('v-card-title', [_vm._v(" Internals "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "small": "",
      "dense": "",
      "right": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.editFeatures
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1), _c('v-card-text', [_c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.chips, function (traitType) {
    return _c('v-chip', {
      key: traitType,
      attrs: {
        "disabled": _vm.disabled
      }
    }, [_vm._v(" " + _vm._s(traitType) + " ")]);
  }), 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "80%"
    },
    model: {
      value: _vm.showDialog,
      callback: function callback($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Internals")]), _c('v-card-text', _vm._l(_vm.value, function (feature, index) {
    return _c('section-feature', {
      key: index,
      attrs: {
        "value": feature,
        "disabled": _vm.disabled
      },
      on: {
        "input": function input($event) {
          return _vm.updateValue([index], $event, _vm.value);
        },
        "delete": function _delete($event) {
          return _vm.deleteFeature(index);
        }
      }
    });
  }), 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" Close ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.addFeature
    }
  }, [_vm._v(" Add ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }