var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('sn-curve', {
    attrs: {
      "value": _vm.value.sn_curve,
      "is-global": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('sn_curve', $event, _vm.value);
      }
    }
  }), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('traits', {
    attrs: {
      "value": _vm.value.trait_types,
      "is-global": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('trait_types', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('safety-factors', {
    attrs: {
      "value": _vm.value.regional_safety_factors,
      "is-global": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('regional_safety_factors', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('knockdowns', {
    attrs: {
      "value": _vm.value.knockdowns,
      "is-global": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('knockdowns', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "width": "100%"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_vm._v(" Sources ")]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "rounded": "",
      "small": "",
      "disabled": _vm.isGlobal
    },
    on: {
      "click": function click($event) {
        return _vm.addSource();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" Add Source ")], 1)], 1), _c('v-card-text', _vm._l(_vm.value.sources, function (source, idx) {
    return _c('v-row', {
      key: idx,
      attrs: {
        "dense": ""
      }
    }, [_c('v-col', {
      staticClass: "p-0 m-0",
      attrs: {
        "md": "11"
      }
    }, [_c('v-select', {
      attrs: {
        "items": _vm.sources,
        "label": "Source",
        "value": _vm.value.sources[idx],
        "disabled": _vm.isGlobal,
        "rules": [_vm.formRules.required]
      },
      on: {
        "input": function input($event) {
          return _vm.updateValue(['sources', idx], $event, _vm.value);
        }
      }
    })], 1), _c('v-col', {
      staticClass: "align-self-center",
      attrs: {
        "md": "1",
        "cols": "auto"
      }
    }, [_c('v-btn', {
      attrs: {
        "disabled": _vm.isGlobal,
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteSource(idx);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }