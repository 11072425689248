var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tower-nav', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return ['data' in _vm.original ? _c('status-change-button', {
          attrs: {
            "status-dates": _vm.towerStatuses,
            "tower-id": _vm.original.uuid
          },
          on: {
            "statusChange": _vm.refreshOriginal
          }
        }) : _vm._e(), _c('v-btn', {
          staticClass: "ml-4",
          attrs: {
            "color": "secondary",
            "disabled": _vm.unedited || _vm.saving || !_vm.isValid || !_vm.isTowerDraft,
            "loading": _vm.saving
          },
          on: {
            "click": function click($event) {
              return _vm.saveTower();
            }
          }
        }, [_vm._v(" Save ")]), _c('v-menu', {
          attrs: {
            "open-on-hover": "",
            "bottom": "",
            "rounded": "lg",
            "offset-y": true
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "large": _vm.$vuetify.breakpoint.mdAndUp
                }
              }, [_vm._v(" mdi-menu ")])], 1)];
            }
          }])
        }, [_c('v-list', [_c('v-list-item-group', {
          model: {
            value: _vm.selection,
            callback: function callback($$v) {
              _vm.selection = $$v;
            },
            expression: "selection"
          }
        }, [_c('v-list-item', {
          attrs: {
            "disabled": !_vm.original || _vm.computing
          },
          on: {
            "click": _vm.recomputeTower
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-reload")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Force Recompute")])], 1)], 1), _c('v-list-item', {
          attrs: {
            "disabled": !_vm.original.history
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.showHistoryModal = true;
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-history")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("History")])], 1)], 1), _c('v-list-item', {
          attrs: {
            "disabled": _vm.disableTowerRelease
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.showStatusModal = true;
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-list-status")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Tower Release")])], 1)], 1), _c('v-list-item', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.copyTower();
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-content-copy")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Copy Tower")])], 1)], 1), _c('v-list-item', {
          attrs: {
            "disabled": !_vm.isTowerDraft
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.deleteTower();
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-delete-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Delete Tower")])], 1)], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _vm.isComputing || !('data' in _vm.original) || _vm.refreshingOriginal ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "background-color": "white"
    }
  }) : _vm._e(), _vm.original.history ? _c('history-modal', {
    attrs: {
      "history": _vm.original.history,
      "object-name": _vm.original.data.name
    },
    model: {
      value: _vm.showHistoryModal,
      callback: function callback($$v) {
        _vm.showHistoryModal = $$v;
      },
      expression: "showHistoryModal"
    }
  }) : _vm._e(), _vm.towerStatuses ? _c('status-modal', {
    attrs: {
      "status-dates": _vm.towerStatuses,
      "tower-id": _vm.original.uuid
    },
    on: {
      "statusChange": _vm.refreshOriginal
    },
    model: {
      value: _vm.showStatusModal,
      callback: function callback($$v) {
        _vm.showStatusModal = $$v;
      },
      expression: "showStatusModal"
    }
  }) : _vm._e(), _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', [_vm.isComputing ? _c('v-alert', {
    attrs: {
      "type": "info",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "mr-4"
        }, [_vm._v(" mdi-autorenew mdi-spin ")])];
      },
      proxy: true
    }], null, false, 1819636738)
  }, [_vm._v(" Tower is Computing... ")]) : _vm._e(), _vm._l(_vm.original.errors, function (error, index) {
    return _c('v-alert', {
      key: index,
      attrs: {
        "type": "error",
        "dismissible": ""
      }
    }, [_vm._v(" " + _vm._s(error) + " ")]);
  }), _vm.original.warnings && _vm.original.warnings.length > 0 ? _c('v-alert', {
    attrs: {
      "type": "warning",
      "dismissible": ""
    }
  }, [_vm._v(" Warnings "), _vm.showMoreWarnings ? _c('a', {
    on: {
      "click": function click($event) {
        _vm.showMoreWarnings = false;
      }
    }
  }, [_vm._v(" show less ")]) : _c('a', {
    on: {
      "click": function click($event) {
        _vm.showMoreWarnings = true;
      }
    }
  }, [_vm._v(" show more ")]), _vm.showMoreWarnings ? _c('ul', _vm._l(_vm.original.warnings, function (warning, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" " + _vm._s(warning) + " ")]);
  }), 0) : _c('div', [_c('ul', [_c('li', [_vm._v(" " + _vm._s(_vm.original.warnings[0]) + " ")]), _c('li', [_vm._v(" ... ")])])])]) : _vm._e(), _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('tower-intent', {
    ref: "modelNumberRef",
    attrs: {
      "model": _vm.original.model,
      "revision": _vm.towerRevision,
      "disabled": !_vm.isTowerDraft
    },
    model: {
      value: _vm.towerIntent,
      callback: function callback($$v) {
        _vm.towerIntent = $$v;
      },
      expression: "towerIntent"
    }
  })], 1)], 2), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-spacer'), _c('v-col', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.original.sketch)
    }
  })]), _c('v-spacer')], 1)], 1), _c('v-card-text', [_c('unit-results', {
    attrs: {
      "results": _vm.towerResults
    }
  })], 1)], 1)], 1)], 1)], 1), _c('confirm-dialog', {
    ref: "confirm"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }