var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Trait type",
      "value": _vm.value.type,
      "items": _vm.traitSchemas.map(function (trait) {
        return trait.type;
      }),
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateType(_vm.traitSchemas, $event);
      }
    }
  })], 1), _vm.value.type === 'BracketWeld' ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "value": _vm.value.name,
      "rules": [_vm.formRules.required],
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('name', $event, _vm.value);
      }
    }
  })], 1) : _vm._e(), _vm.value.type === 'SubmarineDoor' ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Width",
      "value": _vm.value.width,
      "units": "m",
      "required": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('width', $event, _vm.value);
      }
    }
  })], 1) : _vm._e(), _vm.value.type === 'SubmarineDoor' ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Height",
      "value": _vm.value.height,
      "units": "m",
      "required": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('height', $event, _vm.value);
      }
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }