var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [!_vm.hideShape ? _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "value": _vm.value.shape,
      "mandatory": "",
      "row": "",
      "disabled": _vm.disabled
    },
    on: {
      "change": function change($event) {
        return _vm.updateValue('shape', $event, _vm.value);
      }
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "L",
      "value": "L"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "T",
      "value": "T"
    }
  })], 1)], 1) : _vm._e(), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Neck Height",
      "value": _vm.value.neck_height,
      "units": "mm",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('neck_height', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Neck Thickness",
      "value": _vm.value.neck_thickness,
      "units": "mm",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('neck_thickness', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Plate Height",
      "value": _vm.value.plate_height,
      "units": "mm",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('plate_height', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', [_c('numeric-text-field', {
    attrs: {
      "label": "Plate Width",
      "value": _vm.value.plate_width,
      "units": "mm",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('plate_width', $event, _vm.value);
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }