var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.groupBy ? _c('v-list', [_vm._l(_vm.groupedObjects, function (subObjects, groupName) {
    return _c('v-list-group', {
      key: groupName,
      attrs: {
        "no-action": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn() {
          return [_c('v-list-item-title', [_vm._v(" " + _vm._s(groupName === 'GAMESA' ? 'SGRE' : groupName) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_vm.sublist ? _vm._l(subObjects, function (object) {
      return _c('v-list-group', {
        key: object.uuid,
        attrs: {
          "no-action": "",
          "sub-group": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function fn() {
            return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(object.data.name))])], 1)];
          },
          proxy: true
        }], null, true)
      }, [_vm._l(object.model_numbers, function (modelGroup) {
        return _c('v-list-item', {
          key: modelGroup.model_name,
          class: {
            activeLink: _vm.$route.params.turbine === object.uuid && _vm.$route.params.model_number === modelGroup.model_number
          },
          attrs: {
            "to": {
              name: 'towers-list-turbine-model-number',
              params: {
                turbine: object.uuid,
                model_number: modelGroup.model_number
              }
            }
          }
        }, [_c('v-tooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('v-list-item-title', _vm._g(_vm._b({}, 'v-list-item-title', attrs, false), on), [_vm._v(" " + _vm._s(modelGroup.model_name) + " ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(modelGroup.model_name))])])], 1);
      }), _c('v-list-item', {
        class: {
          activeLink: _vm.$route.params.turbine === object.uuid
        },
        attrs: {
          "to": {
            name: 'towers-list-turbine',
            params: {
              turbine: object.uuid
            }
          }
        }
      }, [_vm._v(" Other ")])], 2);
    }) : _vm._l(subObjects, function (object) {
      return _c('v-list-item', {
        key: object.uuid,
        attrs: {
          "no-action": ""
        },
        on: {
          "click": function click($event) {
            return _vm.subObjectNavTo(object);
          }
        }
      }, [_c('v-tooltip', {
        attrs: {
          "right": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function fn(_ref2) {
            var on = _ref2.on,
              attrs = _ref2.attrs;
            return [_c('v-list-item-title', _vm._g(_vm._b({}, 'v-list-item-title', attrs, false), on), [_vm._v(" " + _vm._s(object.data.name) + " ")])];
          }
        }], null, true)
      }, [_c('span', [_vm._v(_vm._s(object.data.name))])])], 1);
    })], 2);
  }), _vm.addOtherOption ? _c('v-list-item', {
    attrs: {
      "to": "/".concat(_vm.$route.path.split('/')[1])
    }
  }, [_c('v-list-item-title', [_vm._v("Other")])], 1) : _vm._e()], 2) : _c('v-list', _vm._l(_vm.objects, function (item, index) {
    return _c('v-list-item', {
      key: index,
      class: {
        activeLink: _vm.$route.params.uuid === item.uuid
      },
      attrs: {
        "to": {
          name: _vm.route,
          params: {
            uuid: item.uuid
          }
        }
      }
    }, [_c('v-tooltip', {
      attrs: {
        "right": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref3) {
          var on = _ref3.on,
            attrs = _ref3.attrs;
          return [_c('v-list-item-title', _vm._g(_vm._b({}, 'v-list-item-title', attrs, false), on), [_vm._v(" " + _vm._s(item.data.name) + " ")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(item.data.name))])])], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }